@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,600');

.example-box {
  background-color: #6096FD;
  /* background-image: linear-gradient(to bottom right, #eb01a5, #d13531); */
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-size: cover;
  color: black;
  font-family: sans-serif;
  font-weight: 200;
  z-index: 0;
}

/* .example-box * {
  z-index: 2;
} */

.background-shapes {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 5076px;
  background-size: 100%;
  animation: 120s infiniteScroll linear infinite;
  background-repeat-x: repeat;
  background-image: url(https://cdn2.hubspot.net/hubfs/53/Pricing%202017%20Assets/marketing/Header_Circles-1.svg);
}

@-webkit-keyframes infiniteScroll {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, -1692px, 0);
    transform: translate3d(0, -1692px, 0);
  }
}

@keyframes infiniteScroll {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, -1692px, 0);
    transform: translate3d(0, -1692px, 0);
  }
}
